import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Layout from "../components/element/layout/Layout";
import PageBanner from "../components/element/page-element/PageBanner";
import LayoutMain from "../components/element/layout/LayoutMain";
import theme, { oo } from "../components/element/theme";
import LayoutColumn from "../components/element/layout/LayoutColumn";
import SEO from "../components/element/SEO";
import { AuthorBio } from "../components/blog/Author";
import { readingTimeFromWords } from "../components/blog/blogUtil";
import { MDXRenderer } from "gatsby-plugin-mdx";


function BlogTemplate(props) {
  const { data: { blog: { body, frontmatter, excerpt, wordCount: { words } } } } = props;
  const authorData = {
    author: frontmatter.author,
    date: frontmatter.updated,
    readingTime: readingTimeFromWords(words),
  };
  const seo = {
    title: `${frontmatter.title} - Oberion`,
    description: excerpt,
    canonical: frontmatter.canonical,
    ogType: "article",
    twitterCard: frontmatter.twitterCard,
    image: frontmatter.image.childImageSharp.fluid.src,
  };

  const pageBanner = <PageBanner heading={frontmatter.title} />;

  return (
    <Layout>
      <SEO {...seo} />
      {pageBanner}
      <LayoutMain backgroundColor={oo.grey8} color={theme.palette.secondary.contrastText}>
        <LayoutColumn maxWidth={oo.textWidth}>

          {/* render image somewhere */}
          {/*<GatsbyImg fluid={featuredImgFluid} />*/}

          <AuthorBio {...authorData} />
          <article className="markdown">
            <MDXRenderer>{body}</MDXRenderer>
          </article>

        </LayoutColumn>
      </LayoutMain>
    </Layout>
  );
}

export const blogQuery = graphql`
  query ($slug: String!) {
    blog: mdx(fields: {slug: {eq: $slug}}) {
      body
      frontmatter {
        title
        published(formatString: "DD MMMM YYYY")
        updated(formatString: "DD MMMM YYYY")
        author
        canonical
        twitterCard
        image {
          childImageSharp {
            fluid(maxWidth: 590) {
              src
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      excerpt(pruneLength: 155)
      fields {slug}
      wordCount {
        words
      }
    }
  }
`;

BlogTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};
export default BlogTemplate;